(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-widget/assets/javascripts/sportradar-widget.js') >= 0) return;  svs.modules.push('/components/sport/sport-widget/assets/javascripts/sportradar-widget.js');
"use strict";

const _excluded = ["widgetType", "matchId", "theme", "widgetProps", "loading"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  useEffect,
  useRef,
  useState,
  useMemo
} = React;
const {
  createWidget,
  WidgetState: SIRWidgetState
} = svs.sport.sportradar;
const {
  SkeletonConfig,
  SkeletonWrapper
} = svs.components.sportinfo.skeletonWrapper;
const SportradarWidgetState = {
  Loading: 1,
  Done: 2,
  Error: 3
};
const SportradarWidget = _ref => {
  let {
      widgetType,
      matchId,
      theme,
      widgetProps = {},
      loading
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const parentElement = useRef();
  const element = useRef();
  const [widgetState, setWidgetState] = useState(SportradarWidget.Loading);
  const skeletonConfig = useMemo(() => {
    var _SkeletonConfig$widge;
    return (_SkeletonConfig$widge = SkeletonConfig[widgetType]) !== null && _SkeletonConfig$widge !== void 0 ? _SkeletonConfig$widge : SkeletonConfig.DEFAULT;
  }, [widgetType]);
  const showSkeleton = loading || widgetState !== SportradarWidgetState.Done && widgetState !== SportradarWidgetState.Error;
  const showError = !loading && widgetState === SportradarWidgetState.Error;
  useEffect(() => {
    let widget;
    if (!loading) {
      const ref = element.current;
      try {
        widget = createWidget({
          matchId,
          widgetType,
          element: ref,
          theme: theme || 'LIGHT',
          widgetProps,
          onStateChange: state => {
            if (state === SIRWidgetState.Alive) {
              setWidgetState(SportradarWidgetState.Done);
            } else if (state === SIRWidgetState.Error) {
              setWidgetState(SportradarWidgetState.Error);
            }
          }
        });
        widget.tryStart();
      } catch (_unused) {
        setWidgetState(SportradarWidgetState.Error);
      }
    }
    return () => {
      if (widget) {
        widget.remove();
      }
    };
  }, [loading, matchId, theme, widgetProps, widgetType]);
  const sportRadarStyle = {};
  if (showSkeleton) {
    sportRadarStyle.display = 'none';
  }
  return React.createElement("div", _extends({
    ref: parentElement
  }, props), showSkeleton && React.createElement(SkeletonWrapper, {
    config: skeletonConfig.skeletonConfig
  }), showError && 'Error', React.createElement("div", {
    ref: element,
    style: sportRadarStyle
  }));
};
setGlobal('svs.components.sport.sportWidget.SportradarWidget', SportradarWidget);

 })(window);